import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _634c7f92 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const _4c956168 = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _7597d482 = () => interopDefault(import('../pages/app-for-professional.vue' /* webpackChunkName: "pages/app-for-professional" */))
const _52f2c86e = () => interopDefault(import('../pages/assets.vue' /* webpackChunkName: "pages/assets" */))
const _ba96ee42 = () => interopDefault(import('../pages/beauty-consultation.vue' /* webpackChunkName: "pages/beauty-consultation" */))
const _efc59bc0 = () => interopDefault(import('../pages/beautyforyou/index.vue' /* webpackChunkName: "pages/beautyforyou/index" */))
const _1c079ed6 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _015a8e16 = () => interopDefault(import('../pages/creators/index.vue' /* webpackChunkName: "pages/creators/index" */))
const _4c10e6e8 = () => interopDefault(import('../pages/elearning.vue' /* webpackChunkName: "pages/elearning" */))
const _a001d760 = () => interopDefault(import('../pages/for-customer/index.vue' /* webpackChunkName: "pages/for-customer/index" */))
const _de95cc54 = () => interopDefault(import('../pages/for-professional.vue' /* webpackChunkName: "pages/for-professional" */))
const _7788f82a = () => interopDefault(import('../pages/heritage.vue' /* webpackChunkName: "pages/heritage" */))
const _01e54128 = () => interopDefault(import('../pages/inquiry.vue' /* webpackChunkName: "pages/inquiry" */))
const _92a2f05c = () => interopDefault(import('../pages/inspiration/index.vue' /* webpackChunkName: "pages/inspiration/index" */))
const _a704b69e = () => interopDefault(import('../pages/news-and-events/index.vue' /* webpackChunkName: "pages/news-and-events/index" */))
const _6b22a50f = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _3a274107 = () => interopDefault(import('../pages/salon-consultation/index.vue' /* webpackChunkName: "pages/salon-consultation/index" */))
const _0346d01d = () => interopDefault(import('../pages/salon-info-update.vue' /* webpackChunkName: "pages/salon-info-update" */))
const _190e852a = () => interopDefault(import('../pages/salon-locator.vue' /* webpackChunkName: "pages/salon-locator" */))
const _0141494c = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _8c621f7c = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _73140386 = () => interopDefault(import('../pages/about-us/brand-pillars.vue' /* webpackChunkName: "pages/about-us/brand-pillars" */))
const _784e8a3a = () => interopDefault(import('../pages/about-us/social.vue' /* webpackChunkName: "pages/about-us/social" */))
const _fbff7eda = () => interopDefault(import('../pages/campaigns/form_2023.vue' /* webpackChunkName: "pages/campaigns/form_2023" */))
const _e0938a58 = () => interopDefault(import('../pages/inspiration/beauty-creators-talk/index.vue' /* webpackChunkName: "pages/inspiration/beauty-creators-talk/index" */))
const _e400cce8 = () => interopDefault(import('../pages/inspiration/beauty-creators-talk/_slug.vue' /* webpackChunkName: "pages/inspiration/beauty-creators-talk/_slug" */))
const _057e6d2d = () => interopDefault(import('../pages/about/_slug.vue' /* webpackChunkName: "pages/about/_slug" */))
const _f332de50 = () => interopDefault(import('../pages/beautyforyou/_slug.vue' /* webpackChunkName: "pages/beautyforyou/_slug" */))
const _04c7d0a6 = () => interopDefault(import('../pages/creators/_slug.vue' /* webpackChunkName: "pages/creators/_slug" */))
const _1a6485f0 = () => interopDefault(import('../pages/education/_slug.vue' /* webpackChunkName: "pages/education/_slug" */))
const _a36f19f0 = () => interopDefault(import('../pages/for-customer/_slug.vue' /* webpackChunkName: "pages/for-customer/_slug" */))
const _961032ec = () => interopDefault(import('../pages/inspiration/_slug.vue' /* webpackChunkName: "pages/inspiration/_slug" */))
const _aa71f92e = () => interopDefault(import('../pages/news-and-events/_slug.vue' /* webpackChunkName: "pages/news-and-events/_slug" */))
const _707d9ed6 = () => interopDefault(import('../pages/product-detail/_slug.vue' /* webpackChunkName: "pages/product-detail/_slug" */))
const _6e4074cf = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _ea05eb5e = () => interopDefault(import('../pages/style/_slug.vue' /* webpackChunkName: "pages/style/_slug" */))
const _fc00a314 = () => interopDefault(import('../pages/range/_range/_slug.vue' /* webpackChunkName: "pages/range/_range/_slug" */))
const _e9d53ed8 = () => interopDefault(import('../pages/salon-consultation/_.vue' /* webpackChunkName: "pages/salon-consultation/_" */))
const _4d46f7c4 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _634c7f92,
    name: "index___en"
  }, {
    path: "/en_hk",
    redirect: "/en-HK"
  }, {
    path: "/en_id",
    redirect: "/id"
  }, {
    path: "/en_my",
    redirect: "/ms"
  }, {
    path: "/en_sg",
    redirect: "/en-SG"
  }, {
    path: "/en-HK",
    component: _634c7f92,
    name: "index___en-HK"
  }, {
    path: "/en-SG",
    component: _634c7f92,
    name: "index___en-SG"
  }, {
    path: "/id",
    component: _634c7f92,
    name: "index___id"
  }, {
    path: "/ja",
    component: _634c7f92,
    name: "index___ja"
  }, {
    path: "/ja_jp",
    redirect: "/ja"
  }, {
    path: "/ko",
    component: _634c7f92,
    name: "index___ko"
  }, {
    path: "/ko_kr",
    redirect: "/ko"
  }, {
    path: "/ms",
    component: _634c7f92,
    name: "index___ms"
  }, {
    path: "/th",
    component: _634c7f92,
    name: "index___th"
  }, {
    path: "/th_th",
    redirect: "/th"
  }, {
    path: "/zh_hk",
    redirect: "/zh-Hant-HK"
  }, {
    path: "/zh_tw",
    redirect: "/zh-Hant-TW"
  }, {
    path: "/zh-Hant-HK",
    component: _634c7f92,
    name: "index___zh-Hant-HK"
  }, {
    path: "/zh-Hant-TW",
    component: _634c7f92,
    name: "index___zh-Hant-TW"
  }, {
    path: "/en-HK/about-us",
    component: _4c956168,
    name: "about-us___en-HK"
  }, {
    path: "/en-HK/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___en-HK"
  }, {
    path: "/en-HK/assets",
    component: _52f2c86e,
    name: "assets___en-HK"
  }, {
    path: "/en-HK/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___en-HK"
  }, {
    path: "/en-HK/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___en-HK"
  }, {
    path: "/en-HK/contact-us",
    component: _1c079ed6,
    name: "contact-us___en-HK"
  }, {
    path: "/en-HK/creators",
    component: _015a8e16,
    name: "creators___en-HK"
  }, {
    path: "/en-HK/elearning",
    component: _4c10e6e8,
    name: "elearning___en-HK"
  }, {
    path: "/en-HK/for-customer",
    component: _a001d760,
    name: "for-customer___en-HK"
  }, {
    path: "/en-HK/for-professional",
    component: _de95cc54,
    name: "for-professional___en-HK"
  }, {
    path: "/en-HK/heritage",
    component: _7788f82a,
    name: "heritage___en-HK"
  }, {
    path: "/en-HK/inquiry",
    component: _01e54128,
    name: "inquiry___en-HK"
  }, {
    path: "/en-HK/inspiration",
    component: _92a2f05c,
    name: "inspiration___en-HK"
  }, {
    path: "/en-HK/news-and-events",
    component: _a704b69e,
    name: "news-and-events___en-HK"
  }, {
    path: "/en-HK/products",
    component: _6b22a50f,
    name: "products___en-HK"
  }, {
    path: "/en-HK/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___en-HK"
  }, {
    path: "/en-HK/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___en-HK"
  }, {
    path: "/en-HK/salon-locator",
    component: _190e852a,
    name: "salon-locator___en-HK"
  }, {
    path: "/en-HK/sitemap",
    component: _0141494c,
    name: "sitemap___en-HK"
  }, {
    path: "/en-HK/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___en-HK"
  }, {
    path: "/en-SG/about-us",
    component: _4c956168,
    name: "about-us___en-SG"
  }, {
    path: "/en-SG/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___en-SG"
  }, {
    path: "/en-SG/assets",
    component: _52f2c86e,
    name: "assets___en-SG"
  }, {
    path: "/en-SG/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___en-SG"
  }, {
    path: "/en-SG/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___en-SG"
  }, {
    path: "/en-SG/contact-us",
    component: _1c079ed6,
    name: "contact-us___en-SG"
  }, {
    path: "/en-SG/creators",
    component: _015a8e16,
    name: "creators___en-SG"
  }, {
    path: "/en-SG/elearning",
    component: _4c10e6e8,
    name: "elearning___en-SG"
  }, {
    path: "/en-SG/for-customer",
    component: _a001d760,
    name: "for-customer___en-SG"
  }, {
    path: "/en-SG/for-professional",
    component: _de95cc54,
    name: "for-professional___en-SG"
  }, {
    path: "/en-SG/heritage",
    component: _7788f82a,
    name: "heritage___en-SG"
  }, {
    path: "/en-SG/inquiry",
    component: _01e54128,
    name: "inquiry___en-SG"
  }, {
    path: "/en-SG/inspiration",
    component: _92a2f05c,
    name: "inspiration___en-SG"
  }, {
    path: "/en-SG/news-and-events",
    component: _a704b69e,
    name: "news-and-events___en-SG"
  }, {
    path: "/en-SG/products",
    component: _6b22a50f,
    name: "products___en-SG"
  }, {
    path: "/en-SG/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___en-SG"
  }, {
    path: "/en-SG/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___en-SG"
  }, {
    path: "/en-SG/salon-locator",
    component: _190e852a,
    name: "salon-locator___en-SG"
  }, {
    path: "/en-SG/sitemap",
    component: _0141494c,
    name: "sitemap___en-SG"
  }, {
    path: "/en-SG/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___en-SG"
  }, {
    path: "/en/about-us",
    component: _4c956168,
    name: "about-us___en"
  }, {
    path: "/en/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___en"
  }, {
    path: "/en/assets",
    component: _52f2c86e,
    name: "assets___en"
  }, {
    path: "/en/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___en"
  }, {
    path: "/en/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___en"
  }, {
    path: "/en/contact-us",
    component: _1c079ed6,
    name: "contact-us___en"
  }, {
    path: "/en/creators",
    component: _015a8e16,
    name: "creators___en"
  }, {
    path: "/en/elearning",
    component: _4c10e6e8,
    name: "elearning___en"
  }, {
    path: "/en/for-customer",
    component: _a001d760,
    name: "for-customer___en"
  }, {
    path: "/en/for-professional",
    component: _de95cc54,
    name: "for-professional___en"
  }, {
    path: "/en/heritage",
    component: _7788f82a,
    name: "heritage___en"
  }, {
    path: "/en/inquiry",
    component: _01e54128,
    name: "inquiry___en"
  }, {
    path: "/en/inspiration",
    component: _92a2f05c,
    name: "inspiration___en"
  }, {
    path: "/en/news-and-events",
    component: _a704b69e,
    name: "news-and-events___en"
  }, {
    path: "/en/products",
    component: _6b22a50f,
    name: "products___en"
  }, {
    path: "/en/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___en"
  }, {
    path: "/en/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___en"
  }, {
    path: "/en/salon-locator",
    component: _190e852a,
    name: "salon-locator___en"
  }, {
    path: "/en/sitemap",
    component: _0141494c,
    name: "sitemap___en"
  }, {
    path: "/en/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___en"
  }, {
    path: "/id/about-us",
    component: _4c956168,
    name: "about-us___id"
  }, {
    path: "/id/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___id"
  }, {
    path: "/id/assets",
    component: _52f2c86e,
    name: "assets___id"
  }, {
    path: "/id/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___id"
  }, {
    path: "/id/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___id"
  }, {
    path: "/id/contact-us",
    component: _1c079ed6,
    name: "contact-us___id"
  }, {
    path: "/id/creators",
    component: _015a8e16,
    name: "creators___id"
  }, {
    path: "/id/elearning",
    component: _4c10e6e8,
    name: "elearning___id"
  }, {
    path: "/id/for-customer",
    component: _a001d760,
    name: "for-customer___id"
  }, {
    path: "/id/for-professional",
    component: _de95cc54,
    name: "for-professional___id"
  }, {
    path: "/id/heritage",
    component: _7788f82a,
    name: "heritage___id"
  }, {
    path: "/id/inquiry",
    component: _01e54128,
    name: "inquiry___id"
  }, {
    path: "/id/inspiration",
    component: _92a2f05c,
    name: "inspiration___id"
  }, {
    path: "/id/news-and-events",
    component: _a704b69e,
    name: "news-and-events___id"
  }, {
    path: "/id/products",
    component: _6b22a50f,
    name: "products___id"
  }, {
    path: "/id/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___id"
  }, {
    path: "/id/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___id"
  }, {
    path: "/id/salon-locator",
    component: _190e852a,
    name: "salon-locator___id"
  }, {
    path: "/id/sitemap",
    component: _0141494c,
    name: "sitemap___id"
  }, {
    path: "/id/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___id"
  }, {
    path: "/ja/about-us",
    component: _4c956168,
    name: "about-us___ja"
  }, {
    path: "/ja/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___ja"
  }, {
    path: "/ja/assets",
    component: _52f2c86e,
    name: "assets___ja"
  }, {
    path: "/ja/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___ja"
  }, {
    path: "/ja/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___ja"
  }, {
    path: "/ja/contact-us",
    component: _1c079ed6,
    name: "contact-us___ja"
  }, {
    path: "/ja/creators",
    component: _015a8e16,
    name: "creators___ja"
  }, {
    path: "/ja/elearning",
    component: _4c10e6e8,
    name: "elearning___ja"
  }, {
    path: "/ja/for-customer",
    component: _a001d760,
    name: "for-customer___ja"
  }, {
    path: "/ja/for-professional",
    component: _de95cc54,
    name: "for-professional___ja"
  }, {
    path: "/ja/heritage",
    component: _7788f82a,
    name: "heritage___ja"
  }, {
    path: "/ja/inquiry",
    component: _01e54128,
    name: "inquiry___ja"
  }, {
    path: "/ja/inspiration",
    component: _92a2f05c,
    name: "inspiration___ja"
  }, {
    path: "/ja/news-and-events",
    component: _a704b69e,
    name: "news-and-events___ja"
  }, {
    path: "/ja/products",
    component: _6b22a50f,
    name: "products___ja"
  }, {
    path: "/ja/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___ja"
  }, {
    path: "/ja/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___ja"
  }, {
    path: "/ja/salon-locator",
    component: _190e852a,
    name: "salon-locator___ja"
  }, {
    path: "/ja/sitemap",
    component: _0141494c,
    name: "sitemap___ja"
  }, {
    path: "/ja/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___ja"
  }, {
    path: "/ko/about-us",
    component: _4c956168,
    name: "about-us___ko"
  }, {
    path: "/ko/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___ko"
  }, {
    path: "/ko/assets",
    component: _52f2c86e,
    name: "assets___ko"
  }, {
    path: "/ko/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___ko"
  }, {
    path: "/ko/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___ko"
  }, {
    path: "/ko/contact-us",
    component: _1c079ed6,
    name: "contact-us___ko"
  }, {
    path: "/ko/creators",
    component: _015a8e16,
    name: "creators___ko"
  }, {
    path: "/ko/elearning",
    component: _4c10e6e8,
    name: "elearning___ko"
  }, {
    path: "/ko/for-customer",
    component: _a001d760,
    name: "for-customer___ko"
  }, {
    path: "/ko/for-professional",
    component: _de95cc54,
    name: "for-professional___ko"
  }, {
    path: "/ko/heritage",
    component: _7788f82a,
    name: "heritage___ko"
  }, {
    path: "/ko/inquiry",
    component: _01e54128,
    name: "inquiry___ko"
  }, {
    path: "/ko/inspiration",
    component: _92a2f05c,
    name: "inspiration___ko"
  }, {
    path: "/ko/news-and-events",
    component: _a704b69e,
    name: "news-and-events___ko"
  }, {
    path: "/ko/products",
    component: _6b22a50f,
    name: "products___ko"
  }, {
    path: "/ko/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___ko"
  }, {
    path: "/ko/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___ko"
  }, {
    path: "/ko/salon-locator",
    component: _190e852a,
    name: "salon-locator___ko"
  }, {
    path: "/ko/sitemap",
    component: _0141494c,
    name: "sitemap___ko"
  }, {
    path: "/ko/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___ko"
  }, {
    path: "/ms/about-us",
    component: _4c956168,
    name: "about-us___ms"
  }, {
    path: "/ms/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___ms"
  }, {
    path: "/ms/assets",
    component: _52f2c86e,
    name: "assets___ms"
  }, {
    path: "/ms/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___ms"
  }, {
    path: "/ms/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___ms"
  }, {
    path: "/ms/contact-us",
    component: _1c079ed6,
    name: "contact-us___ms"
  }, {
    path: "/ms/creators",
    component: _015a8e16,
    name: "creators___ms"
  }, {
    path: "/ms/elearning",
    component: _4c10e6e8,
    name: "elearning___ms"
  }, {
    path: "/ms/for-customer",
    component: _a001d760,
    name: "for-customer___ms"
  }, {
    path: "/ms/for-professional",
    component: _de95cc54,
    name: "for-professional___ms"
  }, {
    path: "/ms/heritage",
    component: _7788f82a,
    name: "heritage___ms"
  }, {
    path: "/ms/inquiry",
    component: _01e54128,
    name: "inquiry___ms"
  }, {
    path: "/ms/inspiration",
    component: _92a2f05c,
    name: "inspiration___ms"
  }, {
    path: "/ms/news-and-events",
    component: _a704b69e,
    name: "news-and-events___ms"
  }, {
    path: "/ms/products",
    component: _6b22a50f,
    name: "products___ms"
  }, {
    path: "/ms/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___ms"
  }, {
    path: "/ms/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___ms"
  }, {
    path: "/ms/salon-locator",
    component: _190e852a,
    name: "salon-locator___ms"
  }, {
    path: "/ms/sitemap",
    component: _0141494c,
    name: "sitemap___ms"
  }, {
    path: "/ms/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___ms"
  }, {
    path: "/th/about-us",
    component: _4c956168,
    name: "about-us___th"
  }, {
    path: "/th/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___th"
  }, {
    path: "/th/assets",
    component: _52f2c86e,
    name: "assets___th"
  }, {
    path: "/th/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___th"
  }, {
    path: "/th/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___th"
  }, {
    path: "/th/contact-us",
    component: _1c079ed6,
    name: "contact-us___th"
  }, {
    path: "/th/creators",
    component: _015a8e16,
    name: "creators___th"
  }, {
    path: "/th/elearning",
    component: _4c10e6e8,
    name: "elearning___th"
  }, {
    path: "/th/for-customer",
    component: _a001d760,
    name: "for-customer___th"
  }, {
    path: "/th/for-professional",
    component: _de95cc54,
    name: "for-professional___th"
  }, {
    path: "/th/heritage",
    component: _7788f82a,
    name: "heritage___th"
  }, {
    path: "/th/inquiry",
    component: _01e54128,
    name: "inquiry___th"
  }, {
    path: "/th/inspiration",
    component: _92a2f05c,
    name: "inspiration___th"
  }, {
    path: "/th/news-and-events",
    component: _a704b69e,
    name: "news-and-events___th"
  }, {
    path: "/th/products",
    component: _6b22a50f,
    name: "products___th"
  }, {
    path: "/th/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___th"
  }, {
    path: "/th/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___th"
  }, {
    path: "/th/salon-locator",
    component: _190e852a,
    name: "salon-locator___th"
  }, {
    path: "/th/sitemap",
    component: _0141494c,
    name: "sitemap___th"
  }, {
    path: "/th/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___th"
  }, {
    path: "/zh-Hant-HK/about-us",
    component: _4c956168,
    name: "about-us___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/assets",
    component: _52f2c86e,
    name: "assets___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/contact-us",
    component: _1c079ed6,
    name: "contact-us___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/creators",
    component: _015a8e16,
    name: "creators___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/elearning",
    component: _4c10e6e8,
    name: "elearning___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/for-customer",
    component: _a001d760,
    name: "for-customer___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/for-professional",
    component: _de95cc54,
    name: "for-professional___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/heritage",
    component: _7788f82a,
    name: "heritage___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/inquiry",
    component: _01e54128,
    name: "inquiry___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/inspiration",
    component: _92a2f05c,
    name: "inspiration___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/news-and-events",
    component: _a704b69e,
    name: "news-and-events___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/products",
    component: _6b22a50f,
    name: "products___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/salon-locator",
    component: _190e852a,
    name: "salon-locator___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/sitemap",
    component: _0141494c,
    name: "sitemap___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___zh-Hant-HK"
  }, {
    path: "/zh-Hant-TW/about-us",
    component: _4c956168,
    name: "about-us___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/app-for-professional",
    component: _7597d482,
    name: "app-for-professional___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/assets",
    component: _52f2c86e,
    name: "assets___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/beauty-consultation",
    component: _ba96ee42,
    name: "beauty-consultation___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/beautyforyou",
    component: _efc59bc0,
    name: "beautyforyou___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/contact-us",
    component: _1c079ed6,
    name: "contact-us___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/creators",
    component: _015a8e16,
    name: "creators___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/elearning",
    component: _4c10e6e8,
    name: "elearning___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/for-customer",
    component: _a001d760,
    name: "for-customer___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/for-professional",
    component: _de95cc54,
    name: "for-professional___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/heritage",
    component: _7788f82a,
    name: "heritage___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/inquiry",
    component: _01e54128,
    name: "inquiry___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/inspiration",
    component: _92a2f05c,
    name: "inspiration___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/news-and-events",
    component: _a704b69e,
    name: "news-and-events___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/products",
    component: _6b22a50f,
    name: "products___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/salon-consultation",
    component: _3a274107,
    name: "salon-consultation___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/salon-info-update",
    component: _0346d01d,
    name: "salon-info-update___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/salon-locator",
    component: _190e852a,
    name: "salon-locator___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/sitemap",
    component: _0141494c,
    name: "sitemap___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/terms-of-use",
    component: _8c621f7c,
    name: "terms-of-use___zh-Hant-TW"
  }, {
    path: "/en-HK/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___en-HK"
  }, {
    path: "/en-HK/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___en-HK"
  }, {
    path: "/en-HK/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___en-HK"
  }, {
    path: "/en-HK/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___en-HK"
  }, {
    path: "/en-SG/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___en-SG"
  }, {
    path: "/en-SG/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___en-SG"
  }, {
    path: "/en-SG/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___en-SG"
  }, {
    path: "/en-SG/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___en-SG"
  }, {
    path: "/en/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___en"
  }, {
    path: "/en/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___en"
  }, {
    path: "/en/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___en"
  }, {
    path: "/en/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___en"
  }, {
    path: "/id/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___id"
  }, {
    path: "/id/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___id"
  }, {
    path: "/id/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___id"
  }, {
    path: "/id/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___id"
  }, {
    path: "/ja/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___ja"
  }, {
    path: "/ja/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___ja"
  }, {
    path: "/ja/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___ja"
  }, {
    path: "/ja/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___ja"
  }, {
    path: "/ko/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___ko"
  }, {
    path: "/ko/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___ko"
  }, {
    path: "/ko/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___ko"
  }, {
    path: "/ko/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___ko"
  }, {
    path: "/ms/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___ms"
  }, {
    path: "/ms/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___ms"
  }, {
    path: "/ms/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___ms"
  }, {
    path: "/ms/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___ms"
  }, {
    path: "/th/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___th"
  }, {
    path: "/th/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___th"
  }, {
    path: "/th/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___th"
  }, {
    path: "/th/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___th"
  }, {
    path: "/zh-Hant-HK/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___zh-Hant-HK"
  }, {
    path: "/zh-Hant-TW/about-us/brand-pillars",
    component: _73140386,
    name: "about-us-brand-pillars___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/about-us/social",
    component: _784e8a3a,
    name: "about-us-social___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/campaigns/form_2023",
    component: _fbff7eda,
    name: "campaigns-form_2023___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/inspiration/beauty-creators-talk",
    component: _e0938a58,
    name: "inspiration-beauty-creators-talk___zh-Hant-TW"
  }, {
    path: "/en-HK/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___en-HK"
  }, {
    path: "/en-SG/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___en-SG"
  }, {
    path: "/en/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___en"
  }, {
    path: "/id/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___id"
  }, {
    path: "/ja/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___ja"
  }, {
    path: "/ko/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___ko"
  }, {
    path: "/ms/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___ms"
  }, {
    path: "/th/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___th"
  }, {
    path: "/zh-Hant-HK/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-TW/inspiration/beauty-creators-talk/:slug?",
    component: _e400cce8,
    name: "inspiration-beauty-creators-talk-slug___zh-Hant-TW"
  }, {
    path: "/en-HK/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___en-HK"
  }, {
    path: "/en-HK/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___en-HK"
  }, {
    path: "/en-HK/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___en-HK"
  }, {
    path: "/en-HK/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___en-HK"
  }, {
    path: "/en-HK/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___en-HK"
  }, {
    path: "/en-HK/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___en-HK"
  }, {
    path: "/en-HK/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___en-HK"
  }, {
    path: "/en-HK/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___en-HK"
  }, {
    path: "/en-HK/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___en-HK"
  }, {
    path: "/en-HK/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___en-HK"
  }, {
    path: "/en-SG/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___en-SG"
  }, {
    path: "/en-SG/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___en-SG"
  }, {
    path: "/en-SG/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___en-SG"
  }, {
    path: "/en-SG/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___en-SG"
  }, {
    path: "/en-SG/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___en-SG"
  }, {
    path: "/en-SG/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___en-SG"
  }, {
    path: "/en-SG/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___en-SG"
  }, {
    path: "/en-SG/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___en-SG"
  }, {
    path: "/en-SG/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___en-SG"
  }, {
    path: "/en-SG/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___en-SG"
  }, {
    path: "/en/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___en"
  }, {
    path: "/en/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___en"
  }, {
    path: "/en/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___en"
  }, {
    path: "/en/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___en"
  }, {
    path: "/en/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___en"
  }, {
    path: "/en/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___en"
  }, {
    path: "/en/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___en"
  }, {
    path: "/en/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___en"
  }, {
    path: "/en/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___en"
  }, {
    path: "/en/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___en"
  }, {
    path: "/id/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___id"
  }, {
    path: "/id/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___id"
  }, {
    path: "/id/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___id"
  }, {
    path: "/id/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___id"
  }, {
    path: "/id/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___id"
  }, {
    path: "/id/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___id"
  }, {
    path: "/id/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___id"
  }, {
    path: "/id/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___id"
  }, {
    path: "/id/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___id"
  }, {
    path: "/id/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___id"
  }, {
    path: "/ja/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___ja"
  }, {
    path: "/ja/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___ja"
  }, {
    path: "/ja/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___ja"
  }, {
    path: "/ja/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___ja"
  }, {
    path: "/ja/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___ja"
  }, {
    path: "/ja/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___ja"
  }, {
    path: "/ja/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___ja"
  }, {
    path: "/ja/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___ja"
  }, {
    path: "/ja/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___ja"
  }, {
    path: "/ja/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___ja"
  }, {
    path: "/ko/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___ko"
  }, {
    path: "/ko/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___ko"
  }, {
    path: "/ko/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___ko"
  }, {
    path: "/ko/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___ko"
  }, {
    path: "/ko/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___ko"
  }, {
    path: "/ko/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___ko"
  }, {
    path: "/ko/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___ko"
  }, {
    path: "/ko/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___ko"
  }, {
    path: "/ko/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___ko"
  }, {
    path: "/ko/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___ko"
  }, {
    path: "/ms/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___ms"
  }, {
    path: "/ms/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___ms"
  }, {
    path: "/ms/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___ms"
  }, {
    path: "/ms/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___ms"
  }, {
    path: "/ms/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___ms"
  }, {
    path: "/ms/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___ms"
  }, {
    path: "/ms/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___ms"
  }, {
    path: "/ms/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___ms"
  }, {
    path: "/ms/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___ms"
  }, {
    path: "/ms/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___ms"
  }, {
    path: "/th/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___th"
  }, {
    path: "/th/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___th"
  }, {
    path: "/th/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___th"
  }, {
    path: "/th/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___th"
  }, {
    path: "/th/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___th"
  }, {
    path: "/th/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___th"
  }, {
    path: "/th/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___th"
  }, {
    path: "/th/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___th"
  }, {
    path: "/th/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___th"
  }, {
    path: "/th/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___th"
  }, {
    path: "/zh-Hant-HK/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-HK/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-TW/about/:slug?",
    component: _057e6d2d,
    name: "about-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/beautyforyou/:slug",
    component: _f332de50,
    name: "beautyforyou-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/creators/:slug",
    component: _04c7d0a6,
    name: "creators-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/education/:slug?",
    component: _1a6485f0,
    name: "education-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/for-customer/:slug?",
    component: _a36f19f0,
    name: "for-customer-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/inspiration/:slug",
    component: _961032ec,
    name: "inspiration-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/news-and-events/:slug?",
    component: _aa71f92e,
    name: "news-and-events-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/product-detail/:slug?",
    component: _707d9ed6,
    name: "product-detail-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/product/:slug?",
    component: _6e4074cf,
    name: "product-slug___zh-Hant-TW"
  }, {
    path: "/zh-Hant-TW/style/:slug?",
    component: _ea05eb5e,
    name: "style-slug___zh-Hant-TW"
  }, {
    path: "/en-HK/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___en-HK"
  }, {
    path: "/en-SG/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___en-SG"
  }, {
    path: "/en/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___en"
  }, {
    path: "/id/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___id"
  }, {
    path: "/ja/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___ja"
  }, {
    path: "/ko/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___ko"
  }, {
    path: "/ms/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___ms"
  }, {
    path: "/th/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___th"
  }, {
    path: "/zh-Hant-HK/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___zh-Hant-HK"
  }, {
    path: "/zh-Hant-TW/range/:range?/:slug?",
    component: _fc00a314,
    name: "range-range-slug___zh-Hant-TW"
  }, {
    path: "/th/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___th"
  }, {
    path: "/zh-Hant-TW/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___zh-Hant-TW"
  }, {
    path: "/en-SG/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___en-SG"
  }, {
    path: "/ms/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___ms"
  }, {
    path: "/ko/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___ko"
  }, {
    path: "/ja/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___ja"
  }, {
    path: "/id/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___id"
  }, {
    path: "/zh-Hant-HK/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___zh-Hant-HK"
  }, {
    path: "/en-HK/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___en-HK"
  }, {
    path: "/en/salon-consultation/*",
    component: _e9d53ed8,
    name: "salon-consultation-all___en"
  }, {
    path: "/th_th/*",
    redirect: "/th/*"
  }, {
    path: "/zh_tw/*",
    redirect: "/zh-Hant-TW/*"
  }, {
    path: "/en_sg/*",
    redirect: "/en-SG/*"
  }, {
    path: "/en_my/*",
    redirect: "/ms/*"
  }, {
    path: "/ko_kr/*",
    redirect: "/ko/*"
  }, {
    path: "/ja_jp/*",
    redirect: "/ja/*"
  }, {
    path: "/en_id/*",
    redirect: "/id/*"
  }, {
    path: "/zh_hk/*",
    redirect: "/zh-Hant-HK/*"
  }, {
    path: "/en_hk/*",
    redirect: "/en-HK/*"
  }, {
    path: "/th/*",
    component: _4d46f7c4,
    name: "all___th"
  }, {
    path: "/zh-Hant-TW/*",
    component: _4d46f7c4,
    name: "all___zh-Hant-TW"
  }, {
    path: "/en-SG/*",
    component: _4d46f7c4,
    name: "all___en-SG"
  }, {
    path: "/ms/*",
    component: _4d46f7c4,
    name: "all___ms"
  }, {
    path: "/ko/*",
    component: _4d46f7c4,
    name: "all___ko"
  }, {
    path: "/ja/*",
    component: _4d46f7c4,
    name: "all___ja"
  }, {
    path: "/id/*",
    component: _4d46f7c4,
    name: "all___id"
  }, {
    path: "/zh-Hant-HK/*",
    component: _4d46f7c4,
    name: "all___zh-Hant-HK"
  }, {
    path: "/en-HK/*",
    component: _4d46f7c4,
    name: "all___en-HK"
  }, {
    path: "/en/*",
    component: _4d46f7c4,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
